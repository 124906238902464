<template>
  <SedeDesdeHastaProducto
    titulo="Reportes de Inventario - Kardex Fechas"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
  />
</template>
  
<script>
import SedeDesdeHastaProducto from "../components/SedeDesdeHastaProducto.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteInventarioMovimientoGeneralUsuarioLayout",
  components: {
    SedeDesdeHastaProducto,
  },
  data() {
    return {
      componenteListado: "ListadoKardexFechas",
    };
  },
  methods: {
    servicioGetReporte:
      ReporteInventarioService.getReporteInventarioKardexFechas,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioKardexFechasPdf,
  },
};
</script>
  