<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table mb-1"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="lista"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
        :pinnedBottomRowData="pinnedBottomRowData"
      >
      </ag-grid-vue>
    </b-overlay>
    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                items.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : items.length
              }}
              de {{ items.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>
  
<script>
import ListadoReportesMixin from "../../mixins/ListadoReportesMixin";
export default {
  name: "ListadoKardexFechas",
  mixins: [ListadoReportesMixin],
  data() {
    return {
      columnDefs: [
        {
          headerName: "USUARIO",
          field: "usuario_nombre",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "FECHA",
          field: "creado_en",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "TIPO",
          field: "tipo",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "CONCEPTO",
          field: "movimiento",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "DETALLE",
          field: "detalle",
          filter: false,
          minWidth: 250,
        },
        {
          headerName: "OBSERVACION",
          field: "observacion",
          filter: false,
          minWidth: 250,
          // maxWidth: 250,
        },
        {
          headerName: "C. INICIAL",
          field: "cantidad_inicial",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "C. ENTRADA",
          cellRenderer: function (param) {
            return param.data.cantidad_entrada == "0.00"
              ? "-"
              : param.data.cantidad_entrada;
          },
          field: "cantidad_entrada",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "C. SALIDA",
          cellRenderer: function (param) {
            return param.data.cantidad_salida == "0.00"
              ? "-"
              : param.data.cantidad_salida;
          },
          field: "cantidad_salida",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "C. TOTAL",
          field: "saldo",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
      ],
    };
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          cantidad_inicial: this.cantidad_inicial,
          cantidad_entrada: this.cantidad_entrada,
          cantidad_salida: this.cantidad_salida,
          saldo: this.saldo,
        },
      ];
    },
    lista() {
      return this.items && this.items[0] ? this.items[0] : [];
    },
    cantidad_inicial() {
      return this.items && this.items[3] ? this.items[3].cantidad_inicial : 0;
    },
    cantidad_entrada() {
      return this.items && this.items[2] ? this.items[2].cantidad_entrada : 0;
    },
    cantidad_salida() {
      return this.items && this.items[1] ? this.items[1].cantidad_salida : 0;
    },
    saldo() {
      return this.items && this.items[4] ? this.items[4].saldo : 0;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
</style>
  