import axiosIns from "@/libs/axios";

const get = async (sede_id) => {
  return await axiosIns.get(`productos?sede_id=${sede_id}`)
  // productos?include=categoria;marca;ubicacion
}

const store = async (data) => {
  return await axiosIns.post('productos', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

const update = async (id, data) => {
  return await axiosIns.post(`productos/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

const findOne = async (term) => {
  return await axiosIns.get(`productos/${term}`)
}

const findOneCodigo = async (term, sede_id) => {
  return await axiosIns.get(`productos/codigo/${term}?sede_id=${sede_id}`)
}

const destroy = async (id) => {
  return await axiosIns.delete(`productos/${id}`)
}

const getCategorias = async (soloPadres) => {
  return await axiosIns.get(`categorias${soloPadres ? '?padres=1' : ''}`)
}

const getCategoriasSubcategorias = async () => {
  return await axiosIns.get(`categorias/subcategorias`)
}


const getMarcas = async () => {
  return await axiosIns.get(`marcas`)
}

const getUbicaciones = async () => {
  return await axiosIns.get(`ubicaciones`)
}

const getProductosActivos = async (sede_id, esServicio) => {
  return await axiosIns.get(`productos/activos?sede_id=${sede_id}&es_servicio=${esServicio}`)
}

const findOneCodigoNoStock = async (term, sedeId, esCotizacion) => {
  const extraParam = esCotizacion ? `&es_cotizacion=${esCotizacion}` : ''
  return await axiosIns.get(`productos/codigo/no-stock/${term}?sede_id=${sedeId}${extraParam}`)
}

const findOneId = async (id, sede_id) => {
  return await axiosIns.get(`productos/cotizado/${id}/?sede_id=${sede_id}`)
}

const findByIds = async (ids, sede_id) => {
  return await axiosIns.post(`productos/cotizados/?sede_id=${sede_id}`, { ids_cotizados: ids })
}

const findOneBySedeId = async (id, sede_id) => {
  return await axiosIns.get(`productos/with-producto-detalle/${id}/?sede_id=${sede_id}`)
}

export default {
  get,
  store,
  update,
  destroy,
  getCategorias,
  getMarcas,
  getUbicaciones,
  findOne,
  getCategoriasSubcategorias,
  findOneCodigo,
  getProductosActivos,
  findOneCodigoNoStock,
  findOneId,
  findByIds,
  findOneBySedeId
}
